import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "empty", requiresAuth: true },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/my-cars",
    name: "search",
    meta: {
      layout: "main",
      title: "My Cars | Ttracking",
    },
    component: () => import("../views/SearchView.vue"),
  },
  {
    path: "/car/:vin",
    name: "car",
    meta: {
      layout: "main",
      title: "Car | Ttracking",
    },
    component: () => import("../views/CarView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
