<template>
  <div>
    <li v-if="lg !== 'en'">
      <button @click="setLang('en')" class="dropdown-item">English</button>
    </li>
    <li v-if="lg !== 'ua'">
      <button @click="setLang('ua')" class="dropdown-item">Ukrainian</button>
    </li>
    <li v-if="lg !== 'pl'">
      <button @click="setLang('pl')" class="dropdown-item">Polish</button>
    </li>
    <li v-if="lg !== 'ru'">
      <button @click="setLang('ru')" class="dropdown-item">Russian</button>
    </li>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lg: "en",
    };
  },
  mounted() {
    try {
      this.lg = this.$store.getters.getLang; // Используйте this.$store для доступа к хранилищу
    } catch (error) {
      console.error("Error in mounted:", error);
    }
  },
  methods: {
    setLang(language) {
      this.lg = language;
      this.$store.commit("setLang", language);
    },
  },
};
</script>
