<template>
  <button v-show="showButton" @click="scrollToTop" id="scrollToTopBtn">
    <i class="icon-up bi bi-arrow-up-circle"></i>
  </button>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showButton = window.scrollY > 20;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
#scrollToTopBtn {
  position: fixed;
  bottom: 65px;
  right: 20px;
  padding: 10px;
  background-color: #161616;
  color: #fff;
  border: none;
  border-radius: 20%;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

#scrollToTopBtn:hover {
  background-color: #3d3d3d;
}

/* Новый класс для отображения кнопки */
.show-button {
  opacity: 1 !important;
}
</style>
