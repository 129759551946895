<template>
  <header>
    <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
      <div class="container">
        <a href="/">
          <img src="@/assets/img/logo.png" alt="logo" width="130" />
        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0 mr-5">
            <li v-if="fav" class="nav-item">
              <router-link to="/my-cars" class="nav-link active">
                <i class="bi bi-star-fill"></i>
                {{ $localize("My Cars", $store.getters.getLang) }}
              </router-link>
            </li>
            <li class="nav-item dropdown lang-link">
              <a
                class="nav-link active dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-translate"></i>
                <span v-if="lg == 'ua'">Ukrainian</span>
                <span v-else-if="lg == 'pl'">Polish</span>
                <span v-else-if="lg == 'ru'">Russian</span>
                <span v-else>English</span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <SelectLang />
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import axios from "axios";
import SelectLang from "@/components/SelectLang.vue";
export default {
  data: () => ({
    fav: false,
    lg: "en",
  }),
  mounted() {
    try {
      this.fetchCars();
      this.lg = this.$store.getters.getLang;

      // Отслеживание изменений в хранилище
      this.$store.watch(
        () => this.$store.getters.getLang,
        (newLang) => {
          this.lg = newLang;
        }
      );
    } catch (error) {
      console.error("Error in mounted:", error);
    }
  },
  components: {
    SelectLang,
  },
  methods: {
    fetchCars() {
      // Проверяем, есть ли значение userIp в localStorage
      const userIp = localStorage.getItem("userIp");

      // Если userIp уже установлен, делаем только второй запрос
      if (userIp) {
        this.postRequest(userIp);
      } else {
        // userIp не установлен, делаем запрос для получения IP
        axios
          .get(this.$api)
          .then((response) => {
            const ip = response.data.userIP;
            localStorage.setItem("userIp", ip);

            // Теперь, когда у нас есть IP-адрес, делаем только второй запрос
            this.postRequest(ip);
          })
          .catch((error) => {
            console.error("Error getting user IP:", error);
          });
      }
    },
    postRequest(ip) {
      // Создаем FormData и отправляем запрос на сервер
      let tabData = new FormData();
      tabData.append("userIp", ip);

      axios
        .post(this.$api + "/default/count-cars", tabData)
        .then((response) => {
          if (response.data !== false) {
            this.fav = true;
          } else {
            this.fav = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
<style scoped>
.bg-page {
  background-color: rgba(30, 30, 30, 1) !important;
}
.navbar {
  padding: 1.2rem 1rem !important;
}
.bg-dark {
  background-color: rgba(30, 30, 30, 1) !important;
}
.lang-link {
  min-width: 130px;
}
.lang-link i {
  margin-right: 5px;
}
</style>
